<template>
  <div>
        <icon-button
              @click.prevent="openPreview(scope.row)"
              content="预览"
              icon="iconfont iconyulan"
              v-if="scope.row.fileId && scope.row.fileId.toString() !== '0'"
            />
            <el-tooltip
              class="item"
              effect="dark"
              :content="getIconTitle(scope.row)"
              placement="top"
              v-if="scope.row.documentId"
            >
              <base-button
                type="text"
                label=""
                :icon="scope.row.fileId && scope.row.fileId.toString() !== '0'?'iconfont iconzhongxinshangchuan ':'iconfont iconshangchuan'"
                btnType="upload"
                accept=".doc,.docx,.xlsx,.xls,.pdf,.png,.jpg,.gif,.bmp"
                :http-request="function (url) {return Upload(url, scope.row, scope.$index)}"
                style="margin: 0px 10px"
              />
            </el-tooltip>
            <icon-button
              @click.prevent="delOtherMaterials(scope.row,scope.$index)"
              content="删除"
              icon="iconfont iconshanchu1"
            />
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  components: { IconButton, BaseButton },
  props: {
    scope: {}
  },
  data () {
    return {

    }
  },
  methods: {
    // 获取文字提示信息
    getIconTitle (row) {
      return (row.fileId && row.fileId.toString() !== '0' ? '重新上传' : '上传')
    },
    // 预览
    openPreview (row) {
      this.$emit('openPreview', row)
    },
    // 文件上传
    Upload (url, row, index) {
      this.$emit('Upload', url, row, index)
    },
    // 删除文件
    delOtherMaterials (row, index) {
      this.$emit('delOtherMaterials', row, index)
    }

  }
}
</script>
