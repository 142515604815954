
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '文件材料题名',
      prop: 'documentName'
    },
    {
      label: '备注',
      prop: 'memo'
    },
    {
      label: '文件',
      prop: 'fileList'
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
