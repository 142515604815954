<template>
<!-- 要素落实制作页面 -->
  <div class="content">
    <back-title lable="要素落实管理" />
    <div class="left">
      <div class="row cont-rl top">
        <div class="row cont-center">
          <div class="bigtitle supplier">
            供应商：{{ rowData.gysCompanyName }}
          </div>
          <div>盖章方式：{{ finBusinessInfo.suppliersSealType }}</div>
          <div>业务编号：{{ rowData.businessNo }}</div>
        </div>
        <div>
          <el-upload
            class="upload-demo"
            ref="upload"
            action="#"
            :show-file-list="false"
            :http-request="(url) => uploadBatchFile(url)"
            accept=".zip,.rar,.7z"
            multiple
          >
            <base-button
              type="primary"
              label="批量上传"
              icon="iconfont iconshangchuan"
            />
          </el-upload>
        </div>
        <div class="examine">
          <base-button
            type="primary"
            label="审核履历"
            icon="iconfont iconzuo"
            @click="getReviewResume"
          />
        </div>
      </div>
      <div class="scrollbox">
<!-- 相关合同制作 -->
        <div v-for="(item,index) of contentMainList" :key="index">
          <title-name-slot :title="item.label" class="solttitle" v-if="tableData[index]&&tableData[index].length>0"></title-name-slot>
          <content-main
            :tableData.sync="tableData[index]"
            :arr.sync="arr"
            :label="item.label"
            @getFilelist="getFilelist"
            :fileIndex='index'
            :suppliersSealType='finBusinessInfo.suppliersSealType'
            ref='contentMain'
          />
        </div>
<!-- 其他文件制作 -->
        <title-name-slot title="其他" class="solttitle">
          <div class="addbtn" slot="operation" @click="addOtherMaterials">
            <div class="addbutton">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </title-name-slot>
        <base-table
          :columns="columnsConfig"
          :showPage="false"
          :tableAttrs="{
            data: tableData[7],
            stripe: true,
          }"
          :type="type"
          ref="tableData"
          :webPage="false"
          :selfChangeHeight="160"
          class="main-page-table"
        >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
          <template slot="action" slot-scope="scope">
            <!-- 其他文件按钮组 -->
            <other-file-button-group :scope="scope" @openPreview='openPreview' @Upload='Upload' @delOtherMaterials='delOtherMaterials' />
          </template>
          <template slot="memo" slot-scope="scope">
            <el-input
              v-model="scope.row.memo"
              placeholder="请输入内容"

              type='memo'
            ></el-input>
            <!-- <div v-else>{{scope.row.memo}}</div> -->
          </template>
          <template slot="documentName" slot-scope="scope">
            <div v-if="scope.row.addtype">
                <el-select v-model="scope.row.documentId" @change="(val) =>changeDocument(val, scope.row)" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.keyId"
                    :label="item.documentName"
                    :value="item.documentId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else>{{scope.row.documentName}}</div>
          </template>
           <template slot="fileList" slot-scope="scope">
        <!-- 文件列按钮组 -->
        <file-button-group v-for="(item ,index) in scope.row.fileListDetailVOS" :key="index"
          :item="item"
          :index="index"
          :scope="scope"
          :fileIndex='fileIndex'
          :isSign='scope.row.isSign'
          @previewShow="openPreview"
          @Upload="Upload"
          :suppliersSealType='suppliersSealType'
          @deleteTemplate="deleteTemplate"
        />
      </template>
        </base-table>
      </div>
    </div>
    <!-- 审核履历 -->
    <drawer :visible.sync="visible" auditType='RZJY' name='审核履历' style="width:480px;right:0px;" :dataList="dataList" ></drawer>
    <!-- 底部按钮组 -->
    <div class="bottombtn">
      <base-button label="保存" @click.prevent="saveInfo('save')" />
      <base-button label="提交" @click.prevent="commitInfo" />
      <base-button label="关闭" @click.prevent="$router.back()" type='default' />
    </div>
    <!-- <pre-view-dialog :visible.sync="previsible" :fileData="prefileData" @dowload='dowload' /> -->
    <pre-view :fileId="prefileData.currentFileId" :isOpen='true' :fileType="prefileData.currentFileFileSuffix" :count="count" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import FileButtonGroup from './components/file-button-group.vue'
import Storage from '@/utils/storage'
import OtherFileButtonGroup from './components/other-file-button-group.vue'

import drawer from '@/pages/business/components/drawer.vue'
import BackTitle from '@/pages/business/components/backtitle.vue'
import titleNameSlot from '@/pages/dashboard/components/title-name-slot.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import ContentMain from './components/content-main.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { columnsConfig } from './utils/elementProduction.config.js'
import { fileListApi, implementApi } from '@/api/businessApi'
import { getDict } from '@/filters/fromDict'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
export default {
  name: 'elementProduction',
  components: {
    OtherFileButtonGroup,
    ContentMain,
    BaseTable,
    BaseButton,
    titleNameSlot,
    BackTitle,
    drawer,

    FileButtonGroup,
    PreView
  },
  watch: {},
  data () {
    return {
      count: 0,
      prefileData: {},
      previsible: false,
      contentMainList: [{ label: '相关合同' }, { label: '内部资料' }, { label: '客户资料' }, { label: '担保人资料' }, { label: '标的资产相关' }, { label: '中登资料文件' }, { label: '要素落实文件' }],
      finBusinessInfo: {},
      rowData: {},
      dataList: [], // 请将审核履历传入组件
      visible: false,
      type: '',
      list: [],
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      tableData: [],
      options: [],
      fileList: [],
      arr: []
    }
  },
  computed: {
    columnsConfig () {
      return columnsConfig(this)
    },
    api () {
      return implementApi
    },
    tags () {
      return JSON.parse(sessionStorage.getItem('routerList'))
    }
  },
  mounted () {
    // this.getFileDocument()
    this.getFilelist()
  },
  methods: {
    // 文件下载
    dowload (data) {
      const fileId = data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 获取文件提名
    getFileDocument () {
      console.log(this.$route.query.keyId, '111')
      fileListApi.getDocument({ businessId: this.$route.query.keyId }).then((res) => {
        this.options = res.data
      })
    },
    // 预览
    openPreview (data) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
      setTimeout(() => {
        // this.previsible = true
        this.prefileData = data
        this.count++
        loading.close()
      }, 2000)
    },
    // 删除其他资料
    delOtherMaterials (row, index) {
      this.$confirm('是否删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = {
            businessId: this.$route.query.businessId,
            documentId: row.documentId,
            keyId: row.keyId
          }
          if (!row.fileId || row.fileId.toString() === '0') {
            this.tableData[7].splice(index, 1)
            return
          }
          data.isContract = 1
          data.documentType = 69
          fileListApi.delete(data).then((res) => {
            if (res.data) {
              this.success('删除成功')
              this.tableData[7].splice(index, 1)
            } else {
              this.error('删除失败')
            }
          })
        })
        .catch(() => {})
    },
    // 选取其他材料-材料题名
    changeDocument (item, data) {
      let state = true
      this.tableData[7].forEach(ele => {
        if (ele.documentIds === item) {
          state = false
        } else {
          this.$set(ele, 'documentIds', ele.documentId)
        }
      })
      if (!state) {
        this.$set(data, 'documentId', '')
        this.$set(data, 'documentIds', '')
        return this.warning('材料题名不能相同')
      } else {
        this.$set(data, 'documentIds', item)
      }
    },
    // 新增其他材料
    addOtherMaterials () {
      // this.getFileDocument()
      this.tableData[7].push({ fileId: 0, addtype: true, otherFile: true })
    },
    // 其他资料上传
    async Upload (param, row, index) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = [
        'doc',
        'docx',
        'xlsx',
        'xls',
        'pdf',
        'png',
        'jpg',
        'jpeg',
        'bmp'
      ]
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传doc,docx,xlsx,xls,pdf,png,jpg,jpeg,bmp格式文件')
        return
      }
      const res = await this.UploadFile(param)
      const data = {
        businessId: this.$route.query.keyId,
        documentId: row.documentId,
        fileId: res.keyId,
        keyId: row.keyId,
        fileName: res.fileName
      }
      // const [obj] = this.options.filter(item => item.documentId === row.documentId)
      console.log(this.options, 'this.options')
      // data.documentName = obj.documentName
      data.documentType = 69
      fileListApi.uploadForKeyId(data).then((res) => {
        if (res.data) {
          this.success('文件上传成功')
          this.tableData[7][index].fileId = data.fileId
          this.tableData[7][index].suffix = suffix
          this.tableData[7][index].keyId = res.data
        }
      })
    },
    async UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 查询信息
    getFilelist () {
      this.api
        .getFileListData({ businessId: this.$route.query.keyId, instanceId: this.$route.query.instanceId })
        .then((res) => {
          if (res.data) {
            this.dataList = res.data.finReviewOpinionInfo
            this.finBusinessInfo = res.data.finBusinessInfo
            this.finBusinessInfo.suppliersSealType = getDict(
              'SUPPLIERS_SEAL_TYPE',
              this.finBusinessInfo.suppliersSealType.toString()
            )
            this.$set(this.tableData, 0, res.data.contractFileList)
            this.$set(this.tableData, 1, res.data.innerSourceFileList)
            this.$set(this.tableData, 2, res.data.customerFileList)
            this.$set(this.tableData, 3, res.data.guarantorFileList)
            this.$set(this.tableData, 4, res.data.assetFileList)
            this.$set(this.tableData, 5, res.data.zdFileList)
            this.$set(this.tableData, 6, res.data.yslsFileList) // 要素落实文件
            res.data.otherFileList.forEach(elem => {
              elem.otherFile = true // 其他资料添加标识
            })
            this.$set(this.tableData, 7, res.data.otherFileList)
            this.formatTableData()
            // 数组处理
            this.tableData.forEach((item) => {
              item.map((item) => {
                if (item.fileType === '0') item.fileType = ''
                return item
              })
            })
          }
        }).catch((err) => {
          if (err.message === '对应资金方产品相关功能暂未上线，敬请期待') {
            const tags = JSON.parse(sessionStorage.getItem('routerList'))
            this.$router.push({ path: tags.path })
          }
        })
    },

    // 批量文件上传
    async uploadBatchFile (file) {
      const res = await this.BatchUploadFile(file)
      const filedata = []
      if (res) {
        res.forEach((item) => {
          filedata.push({ fileName: item.fileName, fileId: item.keyId })
        })
        const data = {
          businessId: this.$route.query.keyId,
          finFileInfoList: filedata
        }
        fileListApi.batchUploadFile(data).then((res) => {
          if (!res.data) {
            this.success('批量上传成功')
            this.getFilelist()
          } else {
            this.error('压缩包中以下文件名与系统文件名不匹配，请确认   ' + res.data)
          }
        })
      }
    },
    async BatchUploadFile (param) {
      var fileType = param.file.name.split('.')
      fileType = fileType[fileType.length - 1]
      if (fileType !== 'zip' && fileType !== 'rar') {
        this.error('请上传zip或rar格式压缩包')
        return
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.batchfile(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 格式化TableData
    formatTableData () {
      this.tableData.forEach((item) => {
        this.arr.push(...item)
      })
      this.arr = this.arr.map((item) => {
        if (item.implementSituation === true) item.implementSituation = 1
        if (item.implementSituation === false) item.implementSituation = 0
        // 设置批量上传fileid
        if (this.fileList) {
          this.fileList.forEach((val) => {
            if (val.fileName === item.documentName) {
              item.fileId = val.keyId
            }
          })
        }
        return item
      })
      return this.arr
    },
    // 查看要素落实审核履历
    getReviewResume () {
      if (this.dataList && this.dataList.length > 0) {
        this.visible = true
      } else {
        this.warning('暂无审核履历')
      }
    },
    // 保存
    saveInfo (type) {
      const data = []
      this.tableData[7].forEach(item => {
        if (this.options) {
          this.options.forEach(elem => {
            if (item.documentId === elem.documentId) {
              item.documentName = elem.documentName
            }
          })
        }
      })
      this.tableData.forEach((item) => {
        item.forEach((elem) => {
          data.push(elem)
        })
      })
      this.api
        .implementSave(data)
        .then((res) => {
          if (type === 'save') {
            this.$message.success('保存成功')
            this.getFilelist()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 构造提交参数
    commitInfo () {
      const data = []
      let pass = true
      this.tableData.forEach((item) => {
        item.forEach((elem) => {
          data.push(elem)
        })
      })
      try {
        data.forEach((item) => {
          if (!item.fileType && item.documentName && !item.otherFile) {
            this.warning('请选择' + item.documentName + '的文件类型')
            pass = false
            throw new Error()
          }
          if (item.fileType !== '1' && item.documentName && !item.otherFile) { // 线下资料必须填写原件份数或复印件份数
            if (!item.originalCopies && !item.copyCopies) {
              this.warning('请填写' + item.documentName + '的原件份数或复印件份数')
              pass = false
              throw new Error()
            }
          }
        })
      } catch (e) {
        console.log(e)
      }

      if (!pass) {
        return
      }
      if (this.tableData[7].length > 0) {
        try {
          this.tableData[7].forEach((item) => {
            if (item.documentId && item.fileId === 0) {
              this.warning('请上传其他资料中的文件')
              pass = false
              throw new Error()
            }
          })
        } catch (e) {
          console.log(e)
        }
      }
      if (!pass) {
        return
      }
      try {
        this.tableData.forEach((item) => {
          item.forEach((elem) => {
            if (elem.implementSituation === 0 && !elem.otherFile) {
              this.warning('请勾选' + elem.documentName + '落实情况')
              pass = false
              throw new Error()
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
      if (!pass) {
        return
      }
      this.submit(data)
    },
    // 提交信息
    submit (data) {
      this.api
        .implementCommit(data)
        .then((res) => {
          this.success('提交成功')
          this.$router.push({ path: this.tags.path })
        })
        .catch((error) => {
          console.log(error)
        })
    }

  },
  created () {
    this.type = this.$route.query.type
    this.rowData = Storage.getSession('row')
  }
}
</script>
<style lang="scss" scoped>
.addbtn {
  background: #fff;
  padding-right: 19px;
  padding-top: 10px;
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
// /deep/ .el-dialog__headerbtn{
//   top: 4px !important;
// }
.bottombtn {
  text-align: center;
  background: #fff;
  padding-bottom: 10px;
}
.top {

  padding-bottom: 11px;
  padding-top: 12px;
  padding-right: 15px;
  .examine {
    margin-left: 20px;
    /deep/ button {
      border-radius: 5px;
      background: #2862E7;
      &:hover {
        background: #ff834a;
      }
    }
  }
  /deep/ button {
    height: 34px;
    padding: 0;
    width: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconshangchuan {
      font-size: 19px;
    }
  }
  > div:nth-child(1) {
    flex: 1;
  }
  > div {
    > div:nth-child(2) {
      font-size: 14px;
      color: #38373a;
      font-weight: 400;
      padding-right: 40px;
    }
    > div:nth-child(3) {
      font-size: 14px;
      color: #38373a;
      font-weight: 400;
    }
  }
  .supplier {
    font-size: 18px;
    font-weight: bold;
    color: #38373a;
    padding-right: 58px;
  }
}
.bigtitle {
  border-left: 6px solid #2862E7;
  padding-left: 10px;
}
.right {
  position: fixed;
  right: 50px;
  top: 20vh;
}
.left {
  background: #fff;
  height: calc(100% - 40px);
  .scrollbox {
    height: calc(100% - 75px);
    overflow: scroll;
  }
}
.row {
  display: flex;
}
.cont-center {
  align-items: center;
}

.cont-rl {
  justify-content: space-between;
}
.content {
  height: calc(100% - 45px) !important;
}
.pd15 {
  padding: 15px;
}
.solttitle {
  margin-left: 11px;
}
</style>
